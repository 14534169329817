<template lang="pug">
v-carousel(v-model='modelCarrusel' hide-delimiters height='300px')
    v-carousel-item(v-for='item of cantidadDeItem' :key='item')
        v-row.justify-center.aling-center
            v-col(cols=5 md=4 v-for='{imagen,nombre,cargo,cv, texto}, k of registroDePersona' :key='k')
                v-hover( v-slot:default="{ hover }")
                    v-card( tile flat :href='cv' target='_blanck')
                        v-img(:src='imagen'  contain height='200').d-md-none    
                        v-img(:src='imagen'  contain height='200').d-none.d-md-block
                            //- v-expand-transition
                            //-     .justify-center.aling-center(
                            //-         v-if="hover"
                            //-         class="d-flex align-center transition-fast-in-fast-out primary  v-card--reveal white--text text-center"
                            //-         style="height: 30%" v-html='texto')
                                   
                                    



                v-row.justify-center.align-center.mt-5.d-none.d-md-block
                    p.text-center.font-weight-ligth(v-html='nombre' style="font-size: 15px")
                    

                v-row.justify-center.align-center.mt-3.d-none.d-md-block
                    p.text-center.font-weight-bold(v-html='cargo' style="font-size: 15px")
                
                v-row.justify-center.align-center.d-md-none
                    p.text-center.font-regular-light(v-html='nombre' style="font-size: 10px")

                v-row.justify-center.align-center.mt-3.d-md-none    
                    p.text-center.font-bold-light(v-html='cargo' style="font-size: 10px")
</template>
<script>

export default {
    data: ()=>({
        modelCarrusel: 0,
    }),
    props: {
        arreglo: Object,
    },
  
    computed: {
        registroDePersona(){
            const customArr = [] 
            if(!this.arreglo) return
            Object.keys(this.arreglo).forEach( key => customArr.push(this.arreglo[key]) ) 
            if(customArr) return customArr.slice(this.modelCarrusel*2, (this.modelCarrusel*2)+2)
            else return []
        },
        cantidadDeItem(){
            const customArr = []
            if(!this.arreglo) return

            Object.keys(this.arreglo).forEach( key => customArr.push(this.arreglo[key]) ) 
            if(customArr) return [...Array(Math.round(customArr.length/2)).keys()]
            else return [1]
        }
    },

}

</script>
<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
  }
  
</style>